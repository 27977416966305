<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div class="columns">
          <div class="column">
            <p class="title">
              <span class="has-badge-child-offset2">Documents</span>
              <span v-if="documentCount"
                class="has-badge-rounded has-badge-info has-badge-large"
                :data-badge="documentCount" />

              <a class="button is-secondary is-pulled-right"
                @click="toggleCategoryModal(true)">
                <span class="icon">
                  <i class="mdi mdi-18px mdi-plus" />
                </span>
                <span>Category</span>
              </a>
            </p>
          </div>
        </div>

        <div v-show="isLoading > 0"
          class="columns is-multiline">
          <div class="section">
            <span class="is-loading-image has-text-grey has-text-centered" />
          </div>
        </div>
        <quote-attachment-category-modal :active="modalOpen"
          @cancel="toggleCategoryModal(false)"
          @categories-changed="getQuoteAttachmentCategories()" />
        <template v-show="!isLoading"
          v-for="category in categories">
          <quote-attachment-category :category="category"
            :key="category.quoteAttachmentCategoryId"
            :quote-id="value.quoteId"
            :attachments="category.attachments"
            :categories="categories"
            @update:attachments="(e)=>handleUpdateAttachments(e)"
            @image-uploaded="getQuoteAttachments()" />
        </template>

      </article>
    </div>
  </div>
</template>

<script>
import QuoteService from './QuoteService'
import { EventHubTypes } from '@/enums'
import QuoteAttachmentCategory from './components/QuoteAttachmentCategory.vue'
import QuoteAttachmentCategoryModal from './components/QuoteAttachmentCategoryModal.vue'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'QuoteDocuments',
  components: {
    QuoteAttachmentCategory,
    QuoteAttachmentCategoryModal
  },
  props: {
    value: null
  },
  data() {
    return {
      categories: [],
      attachments: {},
      isLoading: 0,
      modalOpen: false
    }
  },
  computed: {
    documentCount() {
      return Object.values(this.attachments).reduce((total, row) => total + Object.values(row.quoteAttachments).length, 0)
    }
  },
  created() {
    this.getQuoteAttachmentCategories()
    this.getQuoteAttachments()
  },
  mounted() {},
  methods: {
    handleUpdateAttachments(e) {
      const { category, attachment, isSelected } = e
      //console.log('caught update:attachments', category, attachment, isSelected)
      let cloned = _cloneDeep(this.categories)
      for (let c of cloned) {
        if (c.quoteAttachmentCategoryId != category.quoteAttachmentCategoryId) {
          continue
        }

        for (let a of c.attachments) {
          if (a.quoteAttachmentId != attachment.quoteAttachmentId) {
            continue
          }

          a.isSelected = isSelected
          a.orderIndex = attachment.orderIndex
          //console.log('changed')
        }
      }

      this.categories = cloned
    },
    toggleCategoryModal(value) {
      this.modalOpen = value
    },
    getAttachments(quoteAttachmentCategoryId) {
      return Object.values(Object.values(this.attachments[quoteAttachmentCategoryId]?.quoteAttachments ?? {}))
    },
    sortQuoteAttachments() {
      if (this.categories.length == 0 || Object.keys(this.attachments) == 0) {
        return
      }

      for (let category of this.categories) {
        let attachments = this.getAttachments(category.quoteAttachmentCategoryId).sort(function (a, b) {
          return a.orderIndex > b.orderIndex
        })
        for (let attachment of attachments) {
          attachment.isSelected = false
        }
        category.attachments = attachments
      }
    },
    async getQuoteAttachmentCategories() {
      this.isLoading += 1
      var response = await QuoteService.getQuoteAttachmentCategories()
      this.categories = response
      this.isLoading -= 1
      this.sortQuoteAttachments()
    },
    async getQuoteAttachments() {
      this.isLoading += 1
      var response = await QuoteService.getQuoteAttachments(this.value.quoteId)
      this.attachments = response.data

      let documentCount = Object.values(this.attachments).reduce((total, row) => total + Object.values(row.quoteAttachments).length, 0)
      this.$eventHub.$emit(EventHubTypes.DocumentCountChanged, documentCount)
      this.isLoading -= 1
      this.sortQuoteAttachments()
    }
  }
}
</script>
