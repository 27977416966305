<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem;">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>Document</span>
      </p>
    </header>
    <section class="modal-card-body p-0 m-0"
      style="position:relative;">
      <div class="columns p-0 m-0">
        <div class="column is-2 p-0 m-0">
          <div class="box p-0 m-0"
            :style="{height: maxHeight, overflowY: 'auto', overflowX: 'hidden'}">

            <div class="columns is-multiline p-0 m-0">

              <div class="column is-full p-0 m-0"
                v-for="derived in pageThumbnails"
                :key="derived.quoteAttachmentDerivedId">
                <a @click="viewImage(derived)">
                  <figure class="image is-128x128">
                    <LazyImage class="image is-128x128"
                      style="object-fit: contain;"
                      :src="thumbnailUrl(derived)" />
                  </figure>
                </a>
              </div>
            </div>

          </div>
        </div>
        <div class="column p-0 m-0">
          <div class="box p-0 m-0">
            <figure class="image"
              @click="editImage(mainImageDerived)">
              <LazyImage class="image"
                :src="mainImageLink" />
            </figure>
          </div>
        </div>
      </div>

      <!-- <div class="columns"
        v-if="redaction">
        <div class="column"
          style="height: 600px;">
          <ps-pdf-viewer :quote-id="quoteId"
            :quote-attachment-id="quoteAttachmentId"
            :quote-attachment-category-id="quoteAttachmentCategoryId"
            @close="toggleRedaction(false)"
            @saved="$emit('updated-image')" />
        </div>
      </div> -->
    </section>

    <footer class="modal-card-foot">

      <div :style="`width: 100%; display: flex; justify-content: space-between;`">
        <div style="align-self: flex-start;">
          <button v-if="isPDF && false"
            class="button tooltip"
            @click="()=>{toggleRedaction(true)}"
            data-tooltip="Cancel">
            <span class="icon">
              <i class="mdi mdi-format-color-marker-cancel mdi-18px" />
            </span>
            <span>Redact</span>
          </button>
          <button class="button tooltip"
            @click="()=>{downloadModifiedDocument()}"
            data-tooltip="Cancel">
            <span class="icon">
              <i class="mdi mdi-format-color-marker-cancel mdi-18px" />
            </span>
            <span>Download</span>
          </button>
        </div>
        <div style="align-self: flex-end;">
          <button class="button tooltip"
            @click="cancel()"
            data-tooltip="Cancel">
            <span class="icon">
              <i class="mdi mdi-close mdi-18px" />
            </span>
            <span>Close</span>
          </button>
        </div>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import LazyImage from '@/components/LazyImage'
import QuoteService from '@/views/quote/QuoteService'
import _orderBy from 'lodash/orderBy'
import { OpenImageLink } from '@/components/VuePainterro'
// import PsPdfViewer from '@/components/PsPdfViewer.vue'

export default {
  name: 'QuoteAttachmentViewerModal',
  components: {
    BaseModalEx,
    LazyImage
    // PsPdfViewer
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    },
    quoteId: {
      type: String
    },
    quoteAttachmentId: {
      type: String
    },
    attachment: {
      type: Object
    },
    quoteAttachmentCategoryId: {
      type: String
    }
  },
  data() {
    return {
      isActive: this.active || false,
      selectedDervied: null,
      internalAttachment: this.attachment,
      redaction: false,
      maxHeight: '600px'
    }
  },
  computed: {
    isPDF() {
      return this.internalAttachment.filename.toLowerCase().endsWith('pdf')
    },
    derivedSet() {
      return this.internalAttachment.quoteAttachmentDerivatives.reduce((set, row) => {
        let pageIndex = row.pageIndex
        if (set[pageIndex] === undefined) {
          set[pageIndex] = {}
        }

        set[pageIndex][row.type] = row
        return set
      }, {})
    },
    pageThumbnails() {
      let thumbnails = Object.values(this.derivedSet).reduce((set, row) => {
        if (row['modifiedPageImageThumbnails'] !== undefined) {
          set.push(row['modifiedPageImageThumbnails'])
          return set
        }

        if (row['pageImageThumbnails'] !== undefined) {
          set.push(row['pageImageThumbnails'])
          return set
        }

        return set
      }, [])

      return _orderBy(thumbnails, ['pageIndex'])
    },
    mainImageDerived() {
      let pageIndex = 0
      if (this.selectedDervied !== null) {
        pageIndex = this.selectedDervied.pageIndex
      }

      if (this.derivedSet[pageIndex]['modifiedPageImages'] !== undefined) {
        return this.derivedSet[pageIndex]['modifiedPageImages']
      }

      return this.derivedSet[pageIndex]['pageImages']
    },
    mainImageLink() {
      if (this.mainImageDerived != null) {
        return QuoteService.getQuoteAttachmentUrl(this.quoteId, this.quoteAttachmentId, this.mainImageDerived.quoteAttachmentDerivedId)
      } else {
        return QuoteService.getQuoteAttachmentUrl(this.quoteId, this.quoteAttachmentId)
      }
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    attachment(value) {
      this.internalAttachment = value
    }
  },
  created() {},
  mounted() {},
  methods: {
    downloadUrl(url, fileName) {
      const link = document.createElement('a')
      link.href = url
      link.download = fileName
      link.click()
      URL.revokeObjectURL(link.href)
    },
    async downloadModifiedDocument() {
      const response = await QuoteService.downloadModifiedQuoteAttachment(this.quoteId, this.attachment.quoteAttachmentId)
      let fileName = this.attachment.filename
      if (fileName.indexOf('.pdf') === -1) {
        fileName += '.pdf'
      }
      console.log(response)
      this.downloadUrl('data:application/pdf;base64,' + response.data, fileName)
    },
    toggleRedaction(value) {
      if (value !== undefined) {
        this.redaction = value
        return
      }
      this.redaction = !this.redaction
    },
    ucaseFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    editImage(quoteAttachmentDerived) {
      const vm = this
      OpenImageLink(
        this.mainImageLink,
        (image) => {
          let type = 'modifiedPageImages'
          let pageIndex = quoteAttachmentDerived.pageIndex
          setTimeout(async () => {
            await QuoteService.uploadQuoteAttachmentDerived(this.quoteId, this.quoteAttachmentId, type, pageIndex, image)
            vm.$emit('updated-image')
          }, 0)
        },
        () => {},
        true
      )
    },
    viewImage(derived) {
      this.selectedDervied = derived
    },
    thumbnailUrl(derived) {
      let quoteAttachmentDerivedId = derived.quoteAttachmentDerivedId
      return QuoteService.getQuoteAttachmentUrl(this.quoteId, this.quoteAttachmentId, quoteAttachmentDerivedId)
    },
    cancel() {
      this.toggleRedaction(false)
      this.$emit('cancel')
    }
  }
}
</script>

<style>
</style>